import React, { useState ,useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/home";
import Downtime from "./components/downtime2";

function App() {
  const[isLLMDown, setIsLLMDown]=useState(false);
  const[startTime, setStartTime]=useState("");
  const[endTime,setEndTime]=useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${process.env.REACT_APP_URL1}/downtime` 
       
        const response = await fetch(url);
        const data = await response.json();
        console.log(data);
        console.log(process.env.REACT_APP_API_URL)
        setIsLLMDown(data.isLLMDown);
        setStartTime(data.startTime);
        setEndTime(data.endTime)
      } catch (error) {
        console.error("Error fetching data from API", error);
        setIsLLMDown(false);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <>
      <Router>
        <Routes>
       
        {/* <Route path="/" element={ <Home />} /> */}
        {isLLMDown ? ( 
            <Route path="/" element={<Downtime />} />
          ) : (
            <Route path="/" element={<Home />} />
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;